"use client";

import React, { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { format, isValid, parseISO } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { DateRange } from "react-day-picker";

const parseDate = (dateStr: string | undefined) => {
    if (!dateStr) return undefined;
    const parsedDate = parseISO(dateStr); 
    return isValid(parsedDate) ? parsedDate : undefined; 
};

const SearchByDateFilterPage = ({ defaultDateRange, onChange }: { defaultDateRange: { from?: string; to?: string }; onChange: (date: DateRange | undefined) => void }) => {
    const [date, setDate] = useState<DateRange | undefined>({
        from: parseDate(defaultDateRange?.from),
        to: parseDate(defaultDateRange?.to),
    });

    const handleDateChange = (newDate: DateRange | undefined) => {
        setDate(newDate);
        onChange(newDate);
    };
    
    useEffect(() => {
        if (defaultDateRange) {
            setDate({
                from: parseDate(defaultDateRange?.from),
                to: parseDate(defaultDateRange?.to),
            });
        }
    }, [defaultDateRange]);

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    id="date"
                    variant={"outline"}
                    className={cn(
                        "justify-start hover:bg-transparent hover:scale-[1.02] duration-100 transition-transform text-left font-normal max-[1315px]:text-sm border-0 text-sm w-[300px] max-[1342px]:w-[270px] max-[1267px]:w-[290px] max-[1671px]:w-[320px] max-[1870px]:w-[320px] max-[1730px]:w-[340px] max-[1195px]:w-[250px] max-[1108px]:w-[240px] py-6 bg-transparent border-r rounded-none border-[#807e7e]",
                        !date?.from && "text-muted-foreground"
                    )}
                >
                    <CalendarIcon />
                    {date?.from ? (
                        date.to ? (
                            <>
                                {format(date.from, "LLL dd, y")} -{" "}
                                {format(date.to, "LLL dd, y")}
                            </>
                        ) : (
                            format(date.from, "LLL dd, y")
                        )
                    ) : (
                        <span>Pick a date</span>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="p-0 w-auto" align="start">
                <Calendar
                    initialFocus
                    mode="range"
                    selected={date}
                    onSelect={handleDateChange}
                    numberOfMonths={2}
                    className="custom-calendar"
                />
            </PopoverContent>
        </Popover>
    );
};

export default SearchByDateFilterPage;
