import { useEffect, useState } from "react";
import FilterSection from "./FilterSection";

const Filters = ({ filterData, onFiltersChange, isMobile }: { filterData: any, isMobile?: boolean, onFiltersChange: (processedFilters: Record<string, Set<number>>) => void; }) => {
    const [dynamicFilters, setDynamicFilters] = useState<
        { id: string; title: string; filters: { id: string; description: string; value: string; isDynamic: boolean }[] }[]
    >([]);

    useEffect(() => {
        if (filterData) {
            const formattedFilters = convertToDataArray(filterData);
            setDynamicFilters(formattedFilters);
        }
    }, [filterData]);

    function convertToDataArray(filterData: any) {
        return Object.keys(filterData).map((key, sectionIndex) => {
            const title = formatTitle(key);
            const filters = filterData[key].map((item: any, index: number) => ({
                id: `${key}-${item.id || index}`,
                description: item.name,
                value: item.count?.toString() || null,
                isDynamic: false,
            }));
            return { id: `${key}-${sectionIndex}`, title, filters };
        });
    }

    function formatTitle(key: string) {
        return key
            .replace(/([a-z])([A-Z])/g, "$1 $2")
            .replace(/_/g, " ")
            .replace(/^\w/, (c) => c.toUpperCase());
    }


    const handleFiltersChange = (processedFilters: Record<string, Set<number>>) => {
        onFiltersChange(processedFilters)
    };

    return (
        <div>
            {dynamicFilters.map((section) => (
                <FilterSection
                    key={section.id}
                    title={section.title}
                    filters={section.filters}
                    onFiltersChange={handleFiltersChange}
                    isMobile={isMobile}
                />
            ))}
        </div>
    );
};

export default Filters;
