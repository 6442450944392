import React, { useState } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Separator } from "@/components/ui/separator";

const SortByDrawer = ({
  open,
  closeFilter,
  handleChange,
}: {
  open: boolean;
  closeFilter: (isOpen: boolean) => void;
  handleChange: (value: string) => void;
}) => {
  const [selectedSort, setSelectedSort] = useState("Our top picks");

  const sortOptions = [
    "Our top picks",
    "Homes & apartments first",
    "Price (lowest first)",
    "Price (highest first)",
    "Best reviewed & lowest price",
    "Property rating (high to low)",
    "Property rating (low to high)",
    "Property rating and price",
    "Distance from downtown",
    "Top reviewed",
  ];

  const handleSortChange = (value: string) => {
    setSelectedSort(value);
    handleChange(value);
    closeFilter(false); 
  };

  return (
    <Drawer 
      open={open} 
      onOpenChange={(isOpen) => closeFilter(isOpen)}
    >
      <DrawerContent className="font-poppins">
        <DrawerHeader>
          <DrawerTitle className="font-semibold text-left text-lg">
            Sort by
          </DrawerTitle>
          <Separator />
        </DrawerHeader>

        <RadioGroup
          value={selectedSort}
          onValueChange={handleSortChange}
          className="flex flex-col space-y-4 my-4 px-5 mb-10"
        >
          {sortOptions.map((option) => (
            <label
              key={option}
              className="flex items-center space-x-3 text-sm cursor-pointer"
            >
              <RadioGroupItem value={option} id={option} />
              <span>{option}</span>
            </label>
          ))}
        </RadioGroup>
      </DrawerContent>
    </Drawer>
  );
};

export default SortByDrawer;
