export let VERIFY_CODE = 'verify_code';
export let BASIC_AUTH = 'basic_auth';
export let REFRESH_TOKEN = 'refresh_token';
export let ACCESS_TOKEN = 'access_token';
export let CSRF_TOKEN_KEY = 'csrf_token';

export const PROPERTY_TYPES = {
    APARTMENT: 'apartment',
    HOTEL: 'hotel',
    ROOM: 'room',
}

export type AccessType = 'CUSTOMER_EMAIL_SIGN_UP' | 'ADMIN' | 'SUPER_ADMIN';

interface SortingType {
    label: string;
    value: string;
}

export const SortingTypes: SortingType[] = [
    { label: "Price (lowest first)", value: "PRICE_ASC" },
    { label: "Price (highest first)", value: "PRICE_DESC" },
    { label: "Property rating (low to high)", value: "RATING_ASC" },
    { label: "Property rating (high to low)", value: "RATING_DESC" },
];
