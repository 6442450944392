import React, { useState } from "react";
import { Checkbox } from "../ui/checkbox";
import { Label } from "../ui/label";
import { Button } from "../ui/button";

interface FilterLabelProps {
  description: string;
  value: string;
  isDynamic?: boolean;
  id: string;
  onSelect: (id: string) => void;
}

const FilterLabel: React.FC<FilterLabelProps> = ({
  description,
  value,
  isDynamic = false,
  id,
  onSelect,
}) => {
  const [count, setCount] = useState<number>(0);

  const numericValue = isDynamic ? parseInt(value, 10) || 0 : 0;

  const increment = () => setCount((prev) => Math.min(numericValue, prev + 1));
  const decrement = () => setCount((prev) => Math.max(0, prev - 1));

  const handleCheckboxChange = () => {
    onSelect(id);
  };

  return (
    <div className="flex justify-between items-center w-full">
      <div className="flex justify-between items-center space-x-2 w-full">
        <span className="flex flex-row justify-center items-center gap-2 hover:cursor-pointer">
          <Checkbox
            id={id}
            onClick={handleCheckboxChange}
            className="border-secondary data-[state=checked]:bg-secondary"
          />
          <Label htmlFor={id} className="font-light text-sm hover:cursor-pointer">
            {description}
          </Label>
        </span>
        {isDynamic ? (
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              size="icon"
              className="w-8 h-8"
              onClick={decrement}
              disabled={count <= 0}
            >
              -
            </Button>
            <span className="font-light text-sm">{count}</span>
            <Button
              variant="outline"
              size="icon"
              className="w-8 h-8"
              onClick={increment}
              disabled={count >= numericValue}
            >
              +
            </Button>
          </div>
        ) : (
          <span className="font-light text-sm">{value}</span>
        )}
      </div>
    </div>
  );
};

export default FilterLabel;
