import React, { useState } from "react";
import { BarChart, Bar, ResponsiveContainer, Cell } from "recharts";
import { hotelCountData } from "@/helpers/mockArrays";
import "@/styles/PriceRangeSelector.css";

const PriceRangeAndChartMobile: React.FC = () => {
    const initialMinPrice = 0;
    const initialMaxPrice = 20000;

    const [sliderMinValue] = useState<number>(initialMinPrice);
    const [sliderMaxValue] = useState<number>(initialMaxPrice);
    const [minVal, setMinVal] = useState<number>(initialMinPrice);
    const [maxVal, setMaxVal] = useState<number>(initialMaxPrice);

    const slideMin = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        if (value >= sliderMinValue && maxVal - value >= 5) {
            setMinVal(value);
        }
    };

    const slideMax = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        if (value <= sliderMaxValue && value - minVal >= 5) {
            setMaxVal(value);
        }
    };

    const getBarColor = (range: string): string => {
        const [start, end] = range.split("-").map(Number);
        if (start >= minVal && end <= maxVal) {
            return "#EF5A60";
        }
        return "#eec5c5";
    };

    return (
        <div className="flex flex-col justify-center items-center pb-3 w-full">
            <ResponsiveContainer width="100%" height={90} >
                <BarChart data={hotelCountData}>
                    <Bar dataKey="count" isAnimationActive={false}>
                        {hotelCountData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={getBarColor(entry.range)} radius={5} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>

            <div className="relative left-1 double-slider-box">
                <div className="relative range-slider">
                    <div className="slider-track"></div>
                    <input
                        type="range"
                        min={sliderMinValue}
                        max={sliderMaxValue}
                        value={minVal}
                        onChange={slideMin}
                        className="bottom-0 left-0 absolute"
                    />
                    <input
                        type="range"
                        min={sliderMinValue}
                        max={sliderMaxValue}
                        value={maxVal}
                        onChange={slideMax}
                        className="right-0 bottom-0 absolute"
                    />
                </div>

                <div className="relative top-3 -left-1 flex justify-between items-center input-box">


                    <div>
                        <input
                            className="!px-2 !py-2 border !rounded-2xl min-w-20 !max-w-24 !text-xs text-center"
                            type="text"
                            value={`${minVal} LKR`}
                            onChange={(e) => {
                                const value = parseInt(e.target.value.replace(/\D/g, ""), 10);
                                if (!isNaN(value) && value >= sliderMinValue && value < maxVal - 5) {
                                    setMinVal(value);
                                }
                            }}
                            min={sliderMinValue}
                            max={maxVal - 5}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            className="!px-2 !py-2 border !rounded-2xl min-w-20 !max-w-24 !text-xs text-center"
                            value={maxVal === sliderMaxValue ? `${maxVal} LKR+` : `${maxVal} LKR`}
                            onChange={(e) => {
                                const value = parseInt(e.target.value.replace(/\D/g, ""), 10);
                                if (!isNaN(value) && value > minVal + 5 && value <= sliderMaxValue) {
                                    setMaxVal(value);
                                }
                            }}
                            min={minVal + 5}
                            max={sliderMaxValue}
                        />
                    </div>

                </div>
            </div>
        </div>
    );
};

export default PriceRangeAndChartMobile;