import React from 'react';
import { Skeleton } from "@/components/ui/skeleton";

const ResultCardSkeleton = () => {
    return (
        <div className="flex flex-row justify-between items-center gap-4 bg-white shadow-sm mb-5 ml-5 px-5 py-6 border rounded-lg w-full font-poppins animate-pulse">

            <div className="relative flex w-max h-max">
                <Skeleton className="rounded-lg w-80 h-40" />
            </div>

            <div className="flex flex-col flex-1 justify-between items-start">
                <div className='w-full'>
                    <Skeleton className="bg-primary bg-opacity-15 mb-2 rounded-sm w-[50%] h-5" />

                    <div className='flex justify-start items-start gap-2 text-sm'>
                        <Skeleton className="bg-primary bg-opacity-15 rounded-full w-6 h-6" />
                        <Skeleton className="bg-primary bg-opacity-15 rounded-sm w-24 h-5" />
                    </div>

                    <div className="flex flex-col gap-1 my-2">
                        <Skeleton className="bg-primary bg-opacity-15 mb-1 rounded-sm w-[40%] h-5" />
                        <Skeleton className="bg-primary bg-opacity-15 mb-1 rounded-sm w-[60%] h-4" />
                        <Skeleton className="bg-primary bg-opacity-15 mb-1 rounded-sm w-[50%] h-4" />
                    </div>
                </div>


                <Skeleton className="bg-primary bg-opacity-15 mb-2 rounded-sm w-[60%] h-5" />
            </div>


            <div className="flex flex-col justify-between items-start gap-3 w-36 self-start">
                <div className="flex flex-col justify-start items-start gap-2">

                    <Skeleton className="bg-primary bg-opacity-15 mb-2 rounded-sm w-20 h-5" />
                </div>


                <Skeleton className="bg-primary bg-opacity-15 rounded-sm w-[100%] h-10" />
            </div>
        </div>
    );
}

export default ResultCardSkeleton;
